export default {
  components: {},

  data() {
    return {
      opened: true,
      canShow: true,
    }
  },

  props: {
    title: {
      type: String,
    },

    description: {
      type: String,
    },

    dataAttributes: {
      type: Object,
    },

    number: { type: Object },

    id: {
      type: Number,
    },

    image: {
      type: Object,
    },

    index: {
      type: Number,
    },
  },

  mounted() {},

  beforeDestroy() {},

  methods: {
    toggleCard() {
      this.opened = !this.opened
    },

    focusCard() {
      /*if (!this.opened) {
        this.toggleCard()
      }*/
    },
  },
}
